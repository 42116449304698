import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// double banner image
import banner1 from "../../assests/user/images/home/trendy_banner_111.png";
import banner2 from "../../assests/user/images/home/trendy_banner_22.png";
import "../../styles/user/home.css";
import doubleBanner from "../../assests/user/images/home/double_banner_bg1.jpg";
import doubleBannerMobile from "../../assests/user/images/home/double_banner_mobileImg1.png";

// benefits images
import van from "../../assests/user/images/home/delivery_van.png";
import payment from "../../assests/user/images/home/secure_payment.png";
import phone from "../../assests/user/images/home/telephone.png";
import moneyBack from "../../assests/user/images/home/money_back.png";
import discount from "../../assests/user/images/home/discount.png";

// Image Banner content images
import two_men from "../../assests/user/images/home/two_men.jpeg";
import bg_fish from "../../assests/user/images/home/bg_fish.jpg";
import man_fishing from "../../assests/user/images/home/man_fishing.png";

// Carousel Images
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import carousel_1 from "../../assests/user/images/home/img_3.jpeg";
import carousel_1 from "../../assests/user/images/home/img_1_blur.gif";
import carousel_2 from "../../assests/user/images/home/carousel_3.png";
import carousel_3 from "../../assests/user/images/home/img_2.jpg";
import carousel_side_img from "../../assests/user/images/home/carousel_side_img.PNG";

// component import
import { ScrolltoTopSection } from "../../components/user/Scroll_to_Top_Section";
import WhatsappContact from "../../components/user/WhatsappContact";
import Footer from "../../components/user/Footer";
import NavBar from "../../components/user/NavBar";
import { HotDeals } from "../../components/user/products";
import { Products } from "../../components/user/products";
import { PropularProduct } from "../../components/user/products";

// Carousel Arrow logic
function SampleNextArrow(props) {
  const [nextArrowStyle, setNextArrowStyle] = useState({
    display: "block",
    marginRight: "60px",
  });

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth <= 580) {
        setNextArrowStyle({
          display: "none",
          marginRight: "60px",
        });
      } else {
        setNextArrowStyle({
          display: "block",
          marginRight: "60px",
        });
      }
    };

    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, ...nextArrowStyle }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  const [nextPrevStyle, setNextPrevStyle] = useState({
    display: "block",
    marginLeft: "50px",
    zIndex: "1",
  });

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth <= 580) {
        setNextPrevStyle({
          display: "none",
          marginRight: "60px",
        });
      } else {
        setNextPrevStyle({
          display: "block",
          marginLeft: "50px",
          zIndex: "1",
        });
      }
    };

    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  return (
    <div
      className={className}
      style={{ ...style, ...nextPrevStyle }}
      onClick={onClick}
    />
  );
}

export const Home = () => {
  // Carousel Section
  const settingsMainCarousel = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const [btnValue, setBtnValue] = useState("seaFish");

  const handleTopSellingBtn = (dataName) => {
    setBtnValue(dataName);
  };

  // Timer Logic
  const [launchDate, setLaunchDate] = useState("2024-07-11T00:00:00");

  const [startDay, setStartDay] = useState("00");
  const [startHours, setStartHours] = useState("00");
  const [startMinutes, setStartMinutes] = useState("00");
  const [startSeconds, setStartSeconds] = useState("00");

  const times = { startDay, startHours, startMinutes, startSeconds };

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date(launchDate) - +new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      } else {
        timeLeft = {
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        };
      }

      return timeLeft;
    };

    const updateTimer = () => {
      let timeLeft = calculateTimeLeft();

      if (
        timeLeft.days === "00" &&
        timeLeft.hours === "00" &&
        timeLeft.minutes === "00" &&
        timeLeft.seconds === "00"
      ) {
        const newLaunchDate = new Date(launchDate);
        newLaunchDate.setDate(newLaunchDate.getDate() + 5);
        setLaunchDate(newLaunchDate.toISOString());
        timeLeft = calculateTimeLeft();
      }

      setStartDay(timeLeft.days.toString().padStart(2, "0"));
      setStartHours(timeLeft.hours.toString().padStart(2, "0"));
      setStartMinutes(timeLeft.minutes.toString().padStart(2, "0"));
      setStartSeconds(timeLeft.seconds.toString().padStart(2, "0"));
    };
    const timer = setInterval(updateTimer, 1000);
    updateTimer();

    return () => clearInterval(timer);
  }, [launchDate]);

  const handleClickToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div>
        {/* Header Section */}
        <NavBar />
        <div className="main_body_div">
          <div className="body_content">

            {/* Carousel Section */}
            <section style={{ position: "relative" }}>
              <div className="carousel_flexDiv_overall">
                <div className="carousel_flexDiv">
                  <div className="carousel_flexDiv_one">
                    <div className="slider-container" id="carousel_mainPage">
                      <Slider {...settingsMainCarousel}>
                        <div className="image_carousel">
                          <img
                            id="carousel_flexDiv_img"
                            src={carousel_3}
                            alt="fishing"
                          ></img>
                          <div className="image_carousel_content">
                            <div>
                              <div>
                                <h2 className="image_carousel_main_title">
                                  Dive into Success with Seaboss Fish Franchise
                                </h2>
                              </div>
                              <div>
                                <p className="image_carousel_main_subtitle">
                                  " Transform Your Passion for Seafood into a
                                  Thriving Business "
                                </p>
                              </div>
                              <div>
                                <Link
                                  to={"shop"}
                                  smooth="true"
                                  duration={500}
                                  onClick={handleClickToTop}
                                >
                                  <button className="image_carousel_btn">
                                    SHOP NOW
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="image_carousel">
                          <img
                            id="carousel_flexDiv_img"
                            src={carousel_1}
                            alt="fishing"
                          ></img>
                          <div className="image_carousel_content">
                            <div>
                              <div>
                                <h2 className="image_carousel_main_title">
                                  Sail into Success with Seaboss Fish Franchise
                                </h2>
                              </div>
                              <div>
                                <p className="image_carousel_main_subtitle">
                                  " Chart Your Course to Business Prosperity
                                  with Our Franchise Model "
                                </p>
                              </div>
                              <div>
                                <Link
                                  to={"shop"}
                                  smooth="true"
                                  duration={500}
                                  onClick={handleClickToTop}
                                >
                                  <button className="image_carousel_btn">
                                    SHOP NOW
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="image_carousel">
                          <img
                            id="carousel_flexDiv_img"
                            src={carousel_2}
                            alt="fishing"
                          ></img>
                          <div className="image_carousel_content">
                            <div>
                              <div>
                                <h2 className="image_carousel_main_title">
                                  Discover the Benefits of Seaboss Fish
                                  Franchise
                                </h2>
                              </div>
                              <div>
                                <p className="image_carousel_main_subtitle">
                                  " Your Ultimate Partner in Building a
                                  Successful Fish Franchise Business "
                                </p>
                              </div>
                              <div>
                                <Link
                                  to={"shop"}
                                  smooth="true"
                                  duration={500}
                                  onClick={handleClickToTop}
                                >
                                  <button className="image_carousel_btn">
                                    SHOP NOW
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                  <div className="carousel_flexDiv_two">
                    <img
                      className="carousel_flexDiv_two_img"
                      src={carousel_side_img}
                      alt="fishing"
                    ></img>
                    <div className="carousel_flexDiv_two_absolute">
                      <div>
                        <div>
                          <h6 className="carousel_side_title">50% Off</h6>
                        </div>
                        <div>
                          <h3 className="carousel_side_subtitle">
                            Premium Fishes
                          </h3>
                        </div>
                        <div>
                          <Link
                            to={"shop"}
                            smooth="true"
                            duration={500}
                            className="carousel_view_all_btn"
                            onClick={handleClickToTop}
                          >
                            <div>
                              <span>SHOP NOW</span>
                            </div>
                            <div>
                              <i
                                id="carousel_viewAll_arrow_icon"
                                className="fa-solid fa-arrow-right-long"
                              ></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel_flexDiv_two_mobile">
                    <img
                      className="carousel_flexDiv_two_img"
                      src={carousel_side_img}
                      alt="fishing"
                    ></img>
                    <div className="carousel_flexDiv_two_absolute">
                      <div>
                        <div>
                          <h6 className="carousel_side_title">25% Off</h6>
                        </div>
                        <div>
                          <h3 className="carousel_side_subtitle">
                            Premium Reels
                          </h3>
                        </div>
                        <div>
                          <Link
                            to={"shop"}
                            smooth="true"
                            duration={500}
                            className="carousel_view_all_btn"
                            onClick={handleClickToTop}
                          >
                            <div>
                              <span>SHOP NOW</span>
                            </div>
                            <div>
                              <i
                                id="carousel_viewAll_arrow_icon"
                                className="fa-solid fa-arrow-right-long"
                              ></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Benefits Content */}
            <section id="benefits">
              <div className="benefits_main_title_div">
                <h5 className="benefits_main_title">Benefits</h5>
                <h6 className="benefits_main_subtitle">
                  Services we are offered!
                </h6>
              </div>
              <div className="benefits_overall">
                <div className="benefits_inner" id="benefits_inner">
                  <div className="benefits_img_div">
                    <img
                      className="benefits_img"
                      src={van}
                      alt="delivery_van"
                    ></img>
                  </div>
                  <div
                    className="benefits_content_div"
                    id="benefits_content_div"
                  >
                    <h5 className="benefits_title_name">Free Transport</h5>
                    <p className="benefits_subtitle_name">
                      Deliver in 24 hours max!
                    </p>
                  </div>
                </div>
                <div className="benefits_inner">
                  <div className="benefits_img_div">
                    <img
                      className="benefits_img_big"
                      src={payment}
                      alt="delivery_van"
                    ></img>
                  </div>
                  <div className="benefits_content_div">
                    <h6 className="benefits_title_name">Secure Payment</h6>
                    <p className="benefits_subtitle_name">
                      100% secure payment
                    </p>
                  </div>
                </div>
                <div className="benefits_inner">
                  <div className="benefits_img_div">
                    <img
                      className="benefits_img_big"
                      src={phone}
                      alt="delivery_van"
                    ></img>
                  </div>
                  <div className="benefits_content_div">
                    <h5 className="benefits_title_name">Customer Services</h5>
                    <p className="benefits_subtitle_name">
                      Dedicated 24/7 support
                    </p>
                  </div>
                </div>
                <div className="benefits_inner">
                  <div className="benefits_img_div">
                    <img
                      className="benefits_img_big"
                      src={moneyBack}
                      alt="delivery_van"
                    ></img>
                  </div>
                  <div className="benefits_content_div">
                    <h5 className="benefits_title_name">FishMart Franchise</h5>
                    <p className="benefits_subtitle_name">
                      Get Franchise Contact Our Team
                    </p>
                  </div>
                </div>
                <div className="benefits_inner" id="benefits_inner_right">
                  <div className="benefits_img_div">
                    <img
                      className="benefits_img_big"
                      src={discount}
                      alt="delivery_van"
                    ></img>
                  </div>
                  <div className="benefits_content_div">
                    <h5 className="benefits_title_name">Big Discount</h5>
                    <p className="benefits_subtitle_name">
                      Get everyday discount
                    </p>
                  </div>
                </div>
              </div>
              <div className="benfits_bottom_border_div">
                <div className="benefits_bottom_border"></div>
              </div>
            </section>

            {/* Hot Deals */}
            <section id="hotDeals">
              <HotDeals time={times} />
            </section>

            {/* Image Banner Content */}
            <section>
              <div className="image_banner_content_div">
                <div className="image_banner_content">
                  <div className="image_block_img_div">
                    <img
                      className="image_block_img"
                      src={two_men}
                      alt="fishing"
                    ></img>
                  </div>
                  <div className="image_main_banner_content">
                    <div>
                      <div className="image_main_relative">
                        <div>
                          <div
                            className="image_main_banner_content_div"
                            id="image_main_banner_centerContent"
                          >
                            <div id="image_main_padding_correction">
                              <h4 className="image_main_banner_title">
                                New Fishes
                              </h4>
                              <p className="image_main_banner_subtitle">
                                SeaBoss Fishmart is your go-to destination for
                                premium seafood experiences.
                              </p>
                              <div>
                                <Link
                                  to={"shop"}
                                  smooth="true"
                                  duration={500}
                                  onClick={handleClickToTop}
                                >
                                  <button className="image_main_banner_btn">
                                    SHOP NOW
                                  </button>
                                </Link>
                              </div>
                            </div>
                            <div className="image_main_absolute">
                              <div>
                                <span className="image_main_absolute_discount">
                                  50%
                                </span>
                                <h6 className="image_main_absolute_para">
                                  OFF
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="image_main_banner_content_div"
                          id="image_main_banner_style"
                        >
                          <img
                            className="image_main_banner_content"
                            src={bg_fish}
                            alt="fishing"
                          ></img>
                        </div>
                      </div>
                    </div>

                    <div className="image_main_desktop">
                      <div className="image_main_banner_content_div">
                        <img
                          className="image_main_banner_content"
                          src={man_fishing}
                          alt="fishing"
                        ></img>
                      </div>
                      <div className="image_main_relative">
                        <div
                          className="image_main_banner_content_div"
                          id="image_main_banner_centerContent"
                        >
                          <div id="image_main_padding_correction">
                            <h4 className="image_main_banner_title">
                              Featured Products
                            </h4>
                            <p className="image_main_banner_subtitle">
                              Discover the taste of the ocean with SeaBoss
                              Fishmart.
                            </p>
                            <div // to={"shop"} smooth={true} duration={500}
                            >
                              <Link
                                to={"shop"}
                                smooth="true"
                                duration={500}
                                onClick={handleClickToTop}
                              >
                                <button className="image_main_banner_btn">
                                  SHOP NOW
                                </button>
                              </Link>
                            </div>
                          </div>
                          <div className="image_main_absolute">
                            <div>
                              <span className="image_main_absolute_discount">
                                40%
                              </span>
                              <h6 className="image_main_absolute_para">OFF</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="image_main_mobile">
                      <div className="image_main_relative">
                        <div
                          className="image_main_banner_content_div"
                          id="image_main_banner_centerContent"
                        >
                          <div id="image_main_padding_correction">
                            <h4 className="image_main_banner_title">
                              Featured Products
                            </h4>
                            <p className="image_main_banner_subtitle">
                              Largest online fishing tackle shop in the UK and
                              Europe. Fishinggear is fishing
                            </p>
                            <Link
                              to={"shop"}
                              smooth="true"
                              duration={500}
                              onClick={handleClickToTop}
                            >
                              <button className="image_main_banner_btn">
                                SHOP NOW
                              </button>
                            </Link>
                          </div>
                          <div className="image_main_absolute">
                            <div>
                              <span className="image_main_absolute_discount">
                                30%
                              </span>
                              <h6 className="image_main_absolute_para">OFF</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="image_main_banner_content_div">
                        <img
                          className="image_main_banner_content"
                          src={man_fishing}
                          alt="fishing"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Popular Products */}
            <section id="recommends">
              <PropularProduct />
            </section>

            {/* Trendy Collections */}
            <section>
              <div className="trendy_banner_div">
                <div className="trendy_banner_one">
                  <img src={banner1} alt="demoBanner"></img>
                  <div className="trendy_content_one">
                    <div>
                      <p className="trendy_content_title">Variety Fishes</p>
                      <h5 className="trendy_subContent_title">
                        Top Quality fishes
                      </h5>
                    </div>
                    <div>
                      <Link
                        to={"/shop"}
                        smooth="true"
                        duration={500}
                        className="trendy_view_all_btn"
                        onClick={handleClickToTop}
                      >
                        <div>
                          <span>VIEW COLLECTION</span>
                        </div>
                        <div>
                          <i
                            id="trendy_viewAll_arrow_icon"
                            className="fa-solid fa-arrow-right-long"
                          ></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="trendy_banner_two">
                  <img src={banner2} alt="demoBanner"></img>
                  <div className="trendy_content_two">
                    <div>
                      <p className="trendy_content_title">New Arrival</p>
                      <h5 className="trendy_subContent_title">
                        Salmon fishes 40% OFF
                      </h5>
                    </div>
                    <div>
                      <Link
                        to={"/shop"}
                        smooth="true"
                        duration={500}
                        className="trendy_two_view_all_btn"
                        onClick={handleClickToTop}
                      >
                        <div>
                          <span>SHOP NOW</span>
                        </div>
                        <div>
                          <i
                            id="trendy_viewAll_arrow_icon"
                            className="fa-solid fa-arrow-right-long"
                          ></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>  
            </section>

            {/* Top selling products */}
            <section id="allProducts">
              <div className="top_selling_overall">
                <div>
                  <h4 className="top_selling_title">Top Selling Products</h4>
                </div>
                <div className="banner_stock_content">
                  <h5 className="banner_stock_content_para">Currently Stocks are available in Ananthapuramu outlet Only!</h5>
                </div>
                <div className="top_selling_btns_div">
                  <div className="top_selling_btns_wrapper">
                    <div className="top_selling_btns">
                      <div>
                        <button
                          className={
                            btnValue === "seaFish"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("seaFish");
                          }}
                        >
                          Sea Fish
                        </button>
                      </div>
                      <div>
                        <button
                          className={
                            btnValue === "freshWater"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("freshWater");
                          }}
                        >
                          Fresh Water Fish
                        </button>
                      </div>
                      <div>
                        <button
                          className={
                            btnValue === "prawn"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("prawn");
                          }}
                        >
                          Prawns
                        </button>
                      </div>
                      <div>
                        <button
                          className={
                            btnValue === "crab"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("crab");
                          }}
                        >
                          Crab
                        </button>
                      </div>
                      {/* <div>
                        <button
                          className={
                            btnValue === "squid"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("squid");
                          }}
                        >
                          Squid
                        </button>
                      </div> */}
                      {/* <div>
                        <button
                          className={
                            btnValue === "pickles_frozenItems"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("pickles_frozenItems");
                          }}
                        >
                          Pickles & Masala Items
                        </button>
                      </div> */}
                      {/* <div>
                        <button
                          className={
                            btnValue === "driedSeaFood"
                              ? "top_sell_btn_active"
                              : "top_sell_btn"
                          }
                          onClick={() => {
                            handleTopSellingBtn("driedSeaFood");
                          }}
                        >
                          Dried Seafish
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Products propsProductValue={btnValue} />

            {/* Double Banner */}
            <section>
              <div className="double_banner_div">
                <img
                  className="double_banner_img"
                  src={doubleBanner}
                  alt="doubleDemo"
                ></img>
                <img
                  className="double_banner_img_mobile"
                  src={doubleBannerMobile}
                  alt="doubleDemo"
                ></img>
                <div className="double_banner_contentFlex_div">
                  <div
                    className="double_banner_contentFlex"
                    id="double_banner_contentFlex"
                  >
                    <div>
                      <div className="double_content_one">
                        <div>
                          <p className="trendy_content_title">
                            New Products Added
                          </p>
                          <h5 className="trendy_subContent_title">
                            This Months Deals
                          </h5>
                        </div>
                        <div>
                          <Link
                            className="trendy_view_all_btn"
                            to={"shop"}
                            smooth="true"
                            duration={500}
                            onClick={handleClickToTop}
                          >
                            <div>
                              <span> VIEW CATEGORIES</span>
                            </div>
                            <div>
                              <i
                                id="trendy_viewAll_arrow_icon"
                                className="fa-solid fa-arrow-right-long"
                              ></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="double_content_two">
                        <div>
                          <p className="trendy_content_title">NEW FISHES</p>
                          <h5 className="trendy_subContent_title">
                            50% SELLING OFF
                          </h5>
                        </div>
                        <div>
                          <Link
                            className="trendy_two_view_all_btn"
                            to={"shop"}
                            smooth="true"
                            duration={500}
                            onClick={handleClickToTop}
                          >
                            <div>
                              <span>SHOP NOW</span>
                            </div>
                            <div>
                              <i
                                id="trendy_viewAll_arrow_icon"
                                className="fa-solid fa-arrow-right-long"
                              ></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Subscribe Letter */}
            <WhatsappContact />
          </div>
        </div>
      </div>

      {/* Scroll to Top Section */}
      <ScrolltoTopSection />

      {/* Footer Section */}
      <Footer />
    </div>
  );
};
